import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: 'home',
    component: () => import('@/views/HomePage.vue'),
  },
  {
    path: '/catalog',
    name: 'catalog',
    component: () => import('@/views/CatalogPage.vue')
  },
  {
    path: '/news',
    name: 'news',
    component: () => import('@/views/NewsPage.vue')
  },
  {
    path: '/posts',
    name: 'posts',
    component: () => import('@/views/PostsPage.vue')
  },
  {
    path: '/cart',
    name: 'cart',
    component: () => import('@/views/CartPage.vue')
  },
  {
    path: '/strength',
    name: 'strengthPage',
    component: () => import('@/views/LiqStrength.vue')
  },
  {
    path: '/product/:name/:id',
    name: 'product',
    component: () => import('@/views/ProductPage.vue')
  },
  {
    path: '/regVK',
    name: 'vkGetToken',
    component: () => import('@/components/Auth/VkOauth.vue'),
    props: true
  },
  { 
    path: '/:pathMatch(.*)*', 
    name: 'NOT FOUND',
    component: () => import('@/views/PageNotFound.vue')
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
