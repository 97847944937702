import { useNotification } from "@kyvg/vue3-notification";
const { notify }  = useNotification()

export default function handleError(error :any){
    notify({
        group: 'notification',
        title: `ERROR`,
        text: `
            ${error.response} <br>
            ${error.response.data.message}
        `,
        type: "error",
        duration: 5000000,
        closeOnClick: true,
    });
}