import { createStore } from 'vuex'
import {ref} from 'vue'
import type { Ref} from 'vue'


type ProductValidationType = {
  name: string,
  id: number,
  categories: Array<string>,
  rating: number
};

export default createStore({
  
  state(){
    const products :Array<ProductValidationType> = []

    return{
      _XAccessToken: localStorage.getItem('token') || null,
      _userName: localStorage.getItem('userName') || null,
      _vk_id: localStorage.getItem('vk_id') || null,
      _products: products,
    }
  },
  getters: {
    token(state) :string | null{
      return state._XAccessToken
    },

    userName(state) :string | null{
      return state._userName
    },

    vk_id(state) :string | null{
      return state._vk_id
    },

    products(state) :Array<ProductValidationType>{
      return state._products
    }

  },
  mutations: {
    setToken(state, token :string){
      state._XAccessToken = token
      localStorage.setItem('token', token)
    },
    deleteToken(state){
      state._XAccessToken = null
      localStorage.removeItem('token')
    },

    setUserName(state, userName :string){
      state._userName = userName
      localStorage.setItem('userName', userName)
    },

    setVkId(state, vk_id :string){
      state._vk_id = vk_id
      localStorage.setItem('userName', vk_id)
    },

    products(state, products: Array<ProductValidationType>){
      state._products = products
    }

  },
  actions: {
  },
  modules: {
  }
})

