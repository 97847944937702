import axios from "axios";

const URL = '/api/v1'

const defaultConfig = {
    baseURL: URL,
    headers: {
        'Content-Type': 'application/json'
    }
}

export const defaultAPIInstance = axios.create(defaultConfig)

export const authAPIInstance = axios.create(defaultConfig)

// export const tokenAPIInstance = axios.create(withTokenConfig)


