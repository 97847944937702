import { authAPIInstance } from "../index";

export const API =  {
    login(username :string, password :string){
        const url = '/auth';
        const data = { username, password }
        return authAPIInstance.post(url, data)
    },

    registration(username :string, password :string, email :string){
        const url = '/user';
        const data = {username, password, email}
        return authAPIInstance.post(url, data)
    },

    //code это то сто пришлет VK api
    vkOAuth(code :string){
        const url = '/oauth';
        const data = {code: code}
        return authAPIInstance.post(url, data)
    },
}