export default function getImage(imageName: string) :string{
    let images: __WebpackModuleApi.RequireContext
    let result = '';
    try{
        images = require.context('../../public/assets/', false);
        result = images('./' + imageName);
    }catch{
        images = require.context('../../public/assets/', false);
        result = images('./image.webp');
    }
    
    return result;
}
