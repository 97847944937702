<script lang="js">
    export default {
        name: 'vue-title',
        props: {
            titleElement:{
                type: String
            }
        },
        watch: {
            title: {
                immediate: true,
                handler() {
                    document.title = this.title;
                }
            }
        },
        computed:{
            title: function(){
                if(this.titleElement.length > 0){
                    return `${this.titleElement} - Tesla Labz`
                } else {
                    return "Tesla Labz"
                }
            }
        },
    }
</script>