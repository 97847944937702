<template>
    <button>
        <span>
            <slot></slot>
        </span>
    </button>
</template>

<style scoped>
    button{
        border: 2px solid #f3eeee;
        border-radius: 5em;
        background-color: inherit;
        color: #f3eeee;
        font-weight: 900;
        padding: .5em 1.5em;
        width: 100%;
        transition: .4s;
        cursor: pointer;
    }


    button:hover{
        border-color: red;
        color: #e2dbdb;
        background-color:rgb(39, 38, 36);
        
    }
    @media(min-width: 850px){
        button{
            font-size: 1em;
        }
    }

    @media (max-width: 850px) {    
        button{
            font-size: 1em;
        }
    }
    @media (max-width: 480px) {    
        button{
            font-size: 0.9em;
        }
    }

    @media (max-width: 300px) {    
        button{
            font-size: 0.8em;
        }
    }
</style>