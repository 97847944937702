<template>
    <button>
        <span><slot></slot></span>
    </button>
</template>

<script setup lang="ts">

</script>

<style scoped>
    @import url('https://fonts.googleapis.com/css2?family=Darumadrop+One&display=swap');

    button{
        border: 2px solid white;
        border-radius: 5px;
        background: inherit;
        color: #f3eeee;
        font-weight: 900;
        font-family: 'Darumadrop One', cursive;
        position: relative;
        padding: 0.5em 0.6em;
        min-height: 1.5em;
        transition: .4s;
    }
    span{
        /* При поломке шрифта менять top, left */
        position: absolute;
        top: -88%;
        left: 5%;
        font-size: 1.8em;
    }

    button:hover{
        color: red;
        border-color: red;
        background-color:rgb(39, 38, 36);
    }
</style>